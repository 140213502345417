import React, { useEffect } from "react";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Route, Switch, Redirect, useParams, withRouter } from "react-router-dom";

import Header from "./Header";
import LeftNav from "./LeftNav";
import { Creators } from "../actions/loginActions";
import Global from "./Global";
import NotFound from "./NotFound";
import ErrorBoundary from "./ErrorBoundary";
import Summary from "./Summary";
import MosaicGrid from "./Grid";
import MyCalendar from "./MyCalendar";
import Developers from "./Developers";
import Dashboard from "./Dashboard";
import useTransientElements from "../hooks/useTransientElements";
import moment from "moment";

import styles from "./styles";
import GlobalCss from "./GlobalCss";

import {
	ADMIN,
	CONNECTION,
	CONNECTIONGROUP,
	DROPDOWN,
	GRID,
	MYPROFILE,
	RECYCLEBIN,
	SUMMARY,
	SUMMARY_PARAM,
	SVADMIN,
	USER,
	CUSTOMFIELD,
	GROUP,
	AMENITYCATEGORY,
	AMENITY,
	FORMBUILDER,
	TEMPLATE,
	EXTRANET,
	VISITOR,
	ATTRIBUTE
} from "../common/consts";
import { defaultTheme } from "../common/createTheme";

import SearchResult from "./SearchResult";

const SummaryRedirect = ({ settings }) => {
	const { module, recId } = useParams();
	const summaryTab = settings && settings[module] ? settings[module] : [];
	return summaryTab.length ? <Redirect to={`/${SUMMARY}/${module}/${recId}/${summaryTab[0].gridType.split(".")[1]}`}/> : <Summary />;
};

const SVGrid = () => {
	const { module } = useParams();
	return <MosaicGrid key={`mosaic-${GRID}-${module}`} withMargin moduleName={module} />;
};

const AdminDropdowns = () => {
	const { type } = useParams();
	return <MosaicGrid withMargin key={`mosaic-${ADMIN}-${DROPDOWN}-${type}`} parentModule={DROPDOWN} moduleName={type} parentId={0} />;
};

const AdminExtranetDropdowns = () => {
	const { type } = useParams();
	return <MosaicGrid withMargin key={`mosaic-${ADMIN}-${EXTRANET}-${type}`} parentModule={DROPDOWN} moduleName={type} parentId={0} />;
};

const AdminCustomField = () => {
	const { type } = useParams();
	return <MosaicGrid withMargin key={`mosaic-${ADMIN}-${CUSTOMFIELD}-${type}`} parentModule={ADMIN} moduleName={`${type}${CUSTOMFIELD}`} parentId={0} />;
};

const AdminAmenityModule = () => {
	const { type } = useParams();
	const parent = type === AMENITYCATEGORY ? DROPDOWN : ADMIN;
	return <MosaicGrid withMargin key={`mosaic-${ADMIN}-${AMENITY}-${type}`} parentModule={parent} moduleName={type} parentId={0} />;
};

const AdminAttributeModule = () => {
	const { type } = useParams();
	return <MosaicGrid withMargin key={`mosaic-${ADMIN}-${ATTRIBUTE}-${type}`} parentModule={ADMIN} moduleName={type} parentId={0} />;
};

const RecycleBin = () => {
	const { type } = useParams();
	return <MosaicGrid withMargin key={`mosaic-${ADMIN}-${RECYCLEBIN}-${type}`} parentModule={RECYCLEBIN} moduleName={type} parentId={0} />;
};

const SVAdmin = () => {
	const { type, submodule } = useParams();
	return <MosaicGrid key={`mosaic-${GRID}-${type}`} withMargin moduleName={type} />;
};

const AdminFormBuilder = () => {
	return <MosaicGrid key={`mosaic-${ADMIN}-${FORMBUILDER}`} withMargin moduleName={FORMBUILDER} parentModule={ADMIN} parentId={0}/>;
};

const noop = () => {};
const timeoutInactivitySeconds = 60;
let lastTimeout;
let inactivityTimeout;

const App = props => {
	useEffect(() => {
		props.initialize();
	}, []);

	const { classes, summaryTabSettings, loginErr, clientNotFound, location: { pathname }, loginExpiration, sessionTimeoutHours } = props;
	const { openConfirmDialog, closeDialog } = useTransientElements();

	props.updateExpires();
	const timeout = Math.ceil(sessionTimeoutHours * 60 * 60);
	const currentLocation = window.location.href;
	
	//#region Logout
	const loginExpired = loginExpiration ? moment.utc().isAfter(moment(loginExpiration).add(timeout, "seconds")) : false;

	if (loginExpired) {
		closeDialog && closeDialog();
		props.logout();
	} else if (loginExpiration) {
		clearTimeout(lastTimeout);

		lastTimeout = setTimeout(() => {
			inactivityTimeout = setTimeout(() => {
				props.logout();
			}, timeoutInactivitySeconds * 1000);
			
			openConfirmDialog({
				title: "Your session is about to expire",
				content: "Your session is about to expire due to inactivity. Please choose to Stay Signed In or Log Out. Otherwise you will be logged out automatically.",
				confirmLabel: "Stay Signed In",
				cancelLabel: "Log Out",
				handleConfirm: () => {
					props.updateExpires();
					clearTimeout(inactivityTimeout);
				},
				handleCancel: () => {
					closeDialog && closeDialog();
					props.logout();
				},
				handleExit: () => {
					props.updateExpires();
					clearTimeout(inactivityTimeout);
				}
			});
		}, timeout * 1000);
	}

	const LogoutRedirect = () => {
		history.replaceState && history.replaceState(null, "", window.location.href.replace("/logout", "/"));
		props.logout();
		return <Redirect to={`/`}/>;
	};

	//#endregion

	/**
	 * below set the error boundary key from the pathname, so other routes are still available
	 */
	const pathMatch = pathname.match(/^\/(\w+\/\w+)?(\/[a-z]*)?/);
	const errorBoundaryKey = pathMatch !== null ? pathMatch[0] : "/";

	return (
		<ThemeProvider theme={defaultTheme}>
			<GlobalCss />
			{clientNotFound ? <NotFound /> : !summaryTabSettings && !loginErr ? null : (
				<div onClick={noop} className={classes.appContainer}>
					<div className={classes.headerContainer}>
						<Header />
					</div>
					<div className={classes.mainContainer}>
						{loginErr ? (
							<NotFound message={loginErr} />
						) : (
							<>
								<LeftNav />
								<ErrorBoundary key={errorBoundaryKey}>
									<main className={classes.mainContent}>
										<Switch>
											<Route path={"/searchresults"}>
												<SearchResult />
											</Route>
											<Route path={`/${SUMMARY}/:module/${SUMMARY_PARAM}/:tab`}>
												<Summary />
											</Route>
											<Route path={`/${SUMMARY}/:module/${SUMMARY_PARAM}`}>
												<SummaryRedirect settings={summaryTabSettings}/>
											</Route>
											<Route path={`/${GRID}/:module`}>
												<SVGrid/>
											</Route>
											<Route path={`/${VISITOR}/:module`}>
												<SVGrid/>
											</Route>
											<Route path={`/${MYPROFILE}/:tab`}>
												<Summary adminModule={MYPROFILE} key={"myProfileSummary"}/>
											</Route>
											<Route path={`/${MYPROFILE}`}>
												<Redirect to={`/${MYPROFILE}/${summaryTabSettings?.[MYPROFILE]?.[0]?.gridType?.split(".")[1]}`} />
											</Route>
											<Route path="/mycalendar">
												<MyCalendar/>
											</Route>
											<Route path="/developers">
												<Developers/>
											</Route>
											<Route path={`/${ADMIN}/${USER}/${SUMMARY_PARAM}`}>
												<Summary adminModule={USER}/>
											</Route>
											<Route path={ `/${ADMIN}/${USER}`}>
												<MosaicGrid key={`mosaic-${ADMIN}-${USER}`} withMargin moduleName={USER} />
											</Route>
											<Route path={ `/${ADMIN}/${TEMPLATE}`}>
												<MosaicGrid key={`mosaic-${ADMIN}-${DROPDOWN}-${TEMPLATE}`} parentModule={DROPDOWN} moduleName={TEMPLATE} parentId={0} withMargin />
											</Route>
											<Route path={ `/${ADMIN}/${GROUP}`}>
												<MosaicGrid key={`mosaic-${ADMIN}-${DROPDOWN}-${GROUP}`} parentModule={DROPDOWN} moduleName={GROUP} parentId={0} withMargin />
											</Route>
											<Route path={ `/${ADMIN}/${CONNECTION}`}>
												<MosaicGrid key={`mosaic-${ADMIN}-${DROPDOWN}-${CONNECTION}`} parentModule={DROPDOWN} moduleName={CONNECTION} parentId={0} withMargin />
											</Route>
											<Route path={ `/${ADMIN}/${CONNECTIONGROUP}`}>
												<MosaicGrid key={`mosaic-${ADMIN}-${DROPDOWN}-${CONNECTIONGROUP}`} parentModule={DROPDOWN} moduleName={CONNECTIONGROUP} parentId={0} withMargin />
											</Route>
											<Route path={`/${ADMIN}/${DROPDOWN}/:type`}>
												<AdminDropdowns/>
											</Route>
											<Route path={`/${ADMIN}/${EXTRANET}/:type`}>
												<AdminExtranetDropdowns/>
											</Route>
											<Route path={`/${ADMIN}/${AMENITY}/:type`}>
												<AdminAmenityModule/>
											</Route>
											<Route path={`/${ADMIN}/${ATTRIBUTE}/:type`}>
												<AdminAttributeModule/>
											</Route>
											<Route path={`/${ADMIN}/customField/:type`}>
												<AdminCustomField/>
											</Route>
											<Route path={`/${ADMIN}/${FORMBUILDER}`}>
												<AdminFormBuilder/>
											</Route>
											<Route path={`/${ADMIN}/${RECYCLEBIN}/:type`}>
												<RecycleBin/>
											</Route>
											<Route path={`/${SVADMIN}/:type`}>
												<SVAdmin/>
											</Route>
											<Route path={"/"} exact>
												<Dashboard/>
											</Route>
											<Route path="/logout">
												<LogoutRedirect/>
											</Route>
											<Route>
												<NotFound/>
											</Route>
										</Switch>
									</main>
								</ErrorBoundary>
							</>
						)}
					</div>
				</div>
			)}
			<Global/>
		</ThemeProvider>
	);
};


const mapStateToProps = state => ({
	clientNotFound: state.login.clientNotFound,
	loginErr: state.login.loginErr,
	summaryTabSettings: state.summary.tabs,
	loginExpiration: state.login.loginExpiration,
	sessionTimeoutHours: state.header.sessionTimeoutHours || 2
});

const mapDispatchToProps = {
	initialize: Creators.initialize,
	finishLogin: Creators.finishLogin,
	logout: Creators.logout,
	updateExpires: Creators.updateExpires
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withRouter(App)));
